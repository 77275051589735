import './App.css';
import './assets/html.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';




import {
  Home,
  AboutMe,
  PricingPage,
  Website,
  Contact,
  SignUp,
  SignIn,
  PickDesign,
  SignUpPay,
  UserPanel,
  SMS,
  Email,
  EmailPre,
  TermsPage,
  SupportPage,
  PrivacyPage,
  ErrorPage,
  WebsitePlans,
  ProductsView,
  LivePreview,
  Checkout,
  PreSupport,
}
 from './pages';
import { Footer } from './components';


function App() {
  return (
    <div>

      <Router>

        <div className='parallax'>
          <div className="App">
            <Routes>
              <Route path='/' exact element={<Home />} />
              <Route path='/AboutMe' exact element={<AboutMe />} />
              <Route path='pricing' element={<PricingPage />} />
              <Route path='website' element={<Website />} />
              <Route path='website/:ID' element={<LivePreview />} />
              <Route path='contact' element={<Contact />} />
              <Route path='SignIn' element={<SignIn />} />
              <Route path='SignUp' element={<SignUp />} />
              <Route path='PickDesign' element={<PickDesign />} />
              <Route path='SignUpPay' element={<SignUpPay />} />
              <Route path='UserPanel' element={<UserPanel />} />
              <Route path='SMS' element={<SMS />} />
              <Route path='Email' element={<Email />} />
              <Route path='EmailPre' element={<EmailPre />} />
              <Route path='terms' element={<TermsPage />} />
              <Route path='support' element={<SupportPage />} />
              <Route path='PreSupport' element={<PreSupport />} />
              <Route path='privacy' element={<PrivacyPage />} />
              <Route path='WebsitePlans' element={<WebsitePlans />} />
              <Route path='checkout' element={<Checkout />} />
              <Route path='productsView' element={<ProductsView />} />
              <Route path="*" exact element={<ErrorPage />} />
            </Routes >
          </div>
        </div>
        <Footer />
      </Router >
    </div>


  );
}

export default App;
