import React, { Component } from 'react';
import '../assets/html.css';
import PageHero from './PageHero';
import Heading from '../components/Heading';
import { Navbar, Sidebar, Footer } from '../components';
import { Button, Form, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IMAGES from '../assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight, faMinus, faChevronDown, faCheck, faEye, faCheckCircle, faMailBulk, faMessage, faUser, faPhone, faLink, } from '@fortawesome/free-solid-svg-icons';




class AboutMe extends Component {
  render() {
    return (
      <>
        <Navbar />
        <Sidebar />
        <div class="body-bgcolor">
          <div className="container" align="left">
            <PageHero title='AboutMe' />
            <div className='clear'></div>
            <hr class="featurette-divider my-1" />
            <Heading heading="Know" catchy=" About me" info="Founder's Profile.
"/>
            <div className="container">
              <div class="row">
                <div className='col-md-4'>


                  <div className='rounded center'>
                    <img src={IMAGES.PrakashGoud} alt='Prakash Goud Kotha - Founder' className='center' />

                  </div>

                </div>
                <div className='col-md-8'>

                  <medium class="text-muted">
                    <h5 className='mt-1 text-primary'>Prakash Goud Kotha</h5>
                    <p><i><strong>Founder of </strong><Link to="https://www.ovaltech.in" target="_blank" class="text-primary">Ovals</Link></i></p>
                    <hr/>
                    <p>I'm the founder of Ovals Techsol Pvt Limited (<Link to="https://www.ovalstech.in" target="_blank" class="text-primary">www.ovalstech.in</Link>), 
                    a web services company where we turn cool ideas into amazing websites and web apps. For the past 7 years, I've been diving headfirst into the world 
                    of UI/UX design, working with some fantastic teams to create digital products that are not only beautiful but super user-friendly.
                      <br />
                      <br />
                      My toolbox is filled with the essentials: HTML, CSS, JavaScript, and all the latest frontend frameworks : React.  
                      This lets me whip up websites that look great, work smoothly, and leave a lasting impression. As the head at Ovals, 
                      I've had the pleasure of leading a team of talented folks and delivering top-notch web solutions to clients from all kinds of industries.
                      <br />
                      <br />
                      I'm a big believer in staying ahead of the curve, so I'm always learning about the newest UI/UX trends and techniques. 
                      If you're looking for someone to bring your digital vision to life, let's chat!</p>
                      <p><Link to="https://www.websiteon.co" target="_blank" class="text-primary">websiteon (www.websiteon.co)</Link> is the product of Ovals Tech</p>
                    <p>
                      <Link to="https://www.linkedin.com/in/prakash-kotha-4a92a1287/" target="_blank" class="btn btn-info"><FontAwesomeIcon icon={faLink} size="1x" />&nbsp;Linkedin</Link>&nbsp;
                      <Link to="https://www.facebook.com/OriginalPrakashGoud" target="_blank" class="btn btn-primary"><FontAwesomeIcon icon={faLink} size="1x" />&nbsp;Facebook</Link>&nbsp;
                      <Link to="https://twitter.com/prakashkotha007" target="_blank" class="btn btn-success"><FontAwesomeIcon icon={faLink} size="1x" />&nbsp;Twitter</Link>&nbsp;
                      </p>

                  </medium>

                  <div className='clear'></div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </>
    )
  }
}

export default AboutMe;