import React, { Component } from 'react';
import IMAGES from '../assets';
import { Navbar, Sidebar, Footer } from '../components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageHero from './PageHero';
import Heading from "../components/Heading";
import { faChevronCircleRight, faMinus, faChevronDown, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import ReactPageScroller from 'react-page-scroller';
import ScrollToTop from "react-scroll-to-top";
import video1 from "../assets/AURA_Video.mp4";
import ReactPlayer from 'react-player';





class Home extends Component {
  render() {
    return (
      <>
      <Navbar />
      <Sidebar /> 
      
           <div class="row">
        
        <div className="curve" align="center" >
          <div className="row">
            <div className="col-md-12">
              <img src={IMAGES.change} className="img-fluid animated zoomInDown" />
            </div>
            <div class="col-12">
              <h6 class="display-4 white  animated s1 fadeInUp"><strong>Every subject is a skill, needs to be <Link to="/" class="white" style={{color:"white"}}><u>trained</u></Link> not just </strong>
                <strong><Link to="/" class="white" style={{color:"white"}}><u>taught</u></Link></strong>
              </h6>
            </div>
            <div class="col-12">
              <div class="laptop pt-4 animated s3 pulse">
              <a href="#ex1" rel="modal:open" class="btn btn-lg btn-warning"><em><FontAwesomeIcon icon={faPlayCircle} /> How it works.</em></a>
                <p class="initialism m-2 lead ">
                  <FontAwesomeIcon icon={faChevronDown} className='d1 animated infinite slideInDown' />
                  <div className='clear'></div>
                  <p className='d1 animated infinite flash'><strong>Scroll down to explore.</strong></p>
                </p>
              </div>
              <div id="ex1" class="modal">
              <ReactPlayer
            url= {video1}
            width='100%'
            height='100%'
            controls = {true}
            playing = {true}

          />
              </div>

            </div>
          </div>
        </div>
        
        <div class="col-12  body-bgcolor" align="center">
        <div className='container section-btop'> <div class="mt-1 blue wow animated fadeInDown">
            <FontAwesomeIcon icon={faMinus} /></div>
          <h1 class="display-3 wow animated fadeInUp">Pick 
          <strong class="blue"></strong> a <strong class="blue">path.</strong></h1>
          <p class="mt-1 wow animated fadeInUp">
          what you do, becomes Your identity. 
          </p>
          <div className='row my-2'>
              <div className='col-md-4 mb-1'>

                <div class="card rounded-3 shadow-sm box-shadow">
                 
                  <div class="card-body">
                    <div><h1 class="card-title pricing-card-title pull-left center">Artist</h1></div>
                    <div className='clear'></div>
                    <small class="text-muted fw-light">Self Employed</small>
                    <Link to="/" class="btn btn-lg btn-block btn-warning mt-1" role="button"><em>Select</em></Link>
                    <div className='clear'></div>
                   
                  </div>
                </div>
              </div>
              <div className='col-md-4 mb-1'>

              <div class="card rounded-3 shadow-sm box-shadow">
                 
                 <div class="card-body">
                   <div><h1 class="card-title pricing-card-title pull-left center">Professional</h1></div>
                   <div className='clear'></div>
                   <small class="text-muted fw-light">Employee</small>
                   <Link to="/" class="btn btn-lg btn-block btn-warning mt-1" role="button"><em>Select</em></Link>
                   <div className='clear'></div>
                  
                 </div>
               </div>
              </div>
              <div className='col-md-4 mb-1'>
              <div class="card rounded-3 shadow-sm box-shadow">
                 
                 <div class="card-body">
                   <div><h1 class="card-title pricing-card-title pull-left center">Business</h1></div>
                   <div className='clear'></div>
                   <small class="text-muted fw-light">Employer</small>
                   <Link to="/" class="btn btn-lg btn-block btn-warning mt-1" role="button"><em>Select</em></Link>
                   <div className='clear'></div>
                  
                 </div>
               </div>
              </div>
            </div>
         </div> 
          <div className='clear'></div>
          <div className='container'>

<div className='my-0'>  <Heading heading="" catchy="Features" info="Ouw features are flexible." /></div>
<div class="row features mt-1  text-left">
              <div className='col-md-6 mb-1 border-1'>
                <h2 class="display-3 mt-1 wow  animated fadeInUp"><em>Career Assessment</em></h2>
                <p class="mt-1 wow animated  fadeInUp">A process to identify your interests, skills, and values to make informed career choices. It involves self-reflection, assessments, and research. Benefits include self-awareness, informed decisions, reduced anxiety, job satisfaction, and career success.</p>
              </div>
              <div className='col-md-6 mb-1'>
                <h2 class="display-3 mt-1 wow animated fadeInUp "><em>Training and Certification

</em></h2>
                <p class="mt-1 wow animated fadeInUp ">Processes that enhance skills and knowledge in a specific area. Training typically involves learning new information or techniques, while certification validates proficiency through exams or assessments. Benefits include increased job opportunities, higher salaries, and career advancement.
                </p>

              </div>
              <div className='col-md-6 mb-1'>
                <h2 class="display-3 mt-1 wow animated fadeInUp "><em>Job board access</em></h2>
                <p class="mt-1 wow animated fadeInUp ">The ability to search and browse job listings online. These boards offer features like saved searches, job alerts, and career advice. Accessing job boards is essential for exploring job opportunities and connecting with employers.
                </p>

              </div>
              <div className='col-md-6 mb-1'>
                <h2 class="display-3 mt-1 wow  animated fadeInUp"><em>Networking Opportunities</em></h2>
                <p class="mt-1 wow animated  fadeInUp">Industry events, professional associations, online platforms, community events, and through volunteering. Networking can lead to new opportunities, collaborations, and professional growth.</p>
              </div>
              <div className='col-md-6 mb-1'>
                <h2 class="display-3 mt-1 wow animated fadeInUp "><em>Career Transition</em></h2>
                <p class="mt-1 wow animated fadeInUp ">The process of moving from one job or career path to another. It can involve changing industries, roles, or companies. Factors influencing career transitions include personal goals, skill development, industry shifts, and economic conditions. Successful career transitions often require careful planning, skill development, and networking.
                </p>

              </div>
             
              <div className='col-md-6 mb-1'>
                <h2 class="display-3 mt-1 wow animated fadeInUp "><em>Business based on skills</em></h2>
                <p class="mt-1 wow animated fadeInUp ">Entrepreneurship based on skills involves leveraging your existing talents and abilities to start and run a business. This approach can be particularly rewarding as it allows you to capitalize on what you do best.
                </p>

              </div>

           
            </div>
<div className='homeblocks mt-3'>
<div className='my-5'> <div class="mt-1 blue wow animated fadeInDown">
            <FontAwesomeIcon icon={faMinus} /></div>
          <h1 class="display-3 wow animated fadeInUp">Your <strong class="blue">Choice</strong> of <strong class="blue">skills.</strong></h1>
          <p class="mt-1 wow animated fadeInUp">
            Crafting skills which matches with your path.
          </p>
          <Link to="/" class="btn btn-lg btn-warning box-shadow mt-1 wow animated bounceIn" role="button">
            <em><strong>Let's Go &nbsp;
              <FontAwesomeIcon icon={faChevronCircleRight} />
            </strong></em>
           </Link>
         </div> 

</div>

</div>
          <div className='container section-btop'>

<div className='my-2'>  <Heading heading="The" catchy=" Collaboration" info="A place where artists, employees, employers meet to cater opportunities." /></div>
<span><img src={IMAGES.responsiveconflict} className="img-fluid animated bounceIn" /></span>
</div>
          


   
       

        </div>
        
      </div>
      <ScrollToTop smooth  className='animated s1 slideInUp'/>
      </>
    )
  }
}

export default Home;