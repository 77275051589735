import React, { Component } from 'react';
import '../assets/html.css';
import PageHero from './PageHero';
import Heading from '../components/Heading';
import { Navbar, Sidebar, Footer } from '../components';
import { Button, Form, Card } from 'react-bootstrap';
import Product from "../components/Product";
import Product2 from "../components/Product2";
import Product3 from "../components/Product3";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight, faCartPlus, faChevronDown, faChevronLeft, faChevronRight, faCheckCircle } from '@fortawesome/free-solid-svg-icons';


class SignIn extends Component {
  render() {
    return (
      <>
       <Navbar />
      <Sidebar />
      <div class="body-bgcolor">
        <div className="container" align="left">
        <div class="card-header footer-bg pt-2 pb-3 mt-3 mb-3">
          <div class="card-body">
          <h5 class="card-title text-success text-center pt-1 pb-1"><FontAwesomeIcon icon={faCheckCircle} /> Payment Successful!</h5>
       <div className='clear'></div>
       <br/>
    <p className="card-text text-left">
<strong>Thank you for choosing us!</strong>
<br/>
</p>
<ul className='text-style'>
  
    <li class="card-text" >
      <medium class="text-default">
        <p>👌 Your payment is confirmed. Now sit back and relax while we get your order ready.</p>
    </medium>
    </li>
    <li class="card-text" >
      <medium class="text-default"> 
        <p>📩 You'll receive an email confirmation shortly with details of your purchase.</p>
        </medium>
        </li>
    <li class="card-text" >
      <medium class="text-default">
        <p>🔑 Your order confirmation number is ON12345. Please keep this for your records.</p>
        </medium>
        </li>
      </ul>
      <p class="card-text" ><medium class="text-secondary">If you have any questions, feel free to contact us at <strong>reach@websiteon.co</strong> or <strong>+91-6305575089</strong>.</medium></p>
      <br/>
      <br/>
      <Link to='/UserPanel' class="btn-primary btn-lg btn-success box-shadow wow animated bounceIn text-right pull-right" role="button"> Proceed  <FontAwesomeIcon icon={faChevronCircleRight} /></Link>  
  </div>
     </div>
        </div>
      </div>
      </>
    )
  }
}

export default SignIn;