import React, { Component } from 'react';
import '../assets/html.css';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class HTMLeditor extends Component {
  render() {
    return (

      <>

<Editor />

      </>

    )
  }
}

export default HTMLeditor;  