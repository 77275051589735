import React, { Component } from 'react';
import PageHero from './PageHero';
import Heading from "../components/Heading";
import { Navbar, Sidebar, Footer } from '../components';



class Checkout extends Component {
  render() {
    return (
      <>
       <Navbar />
      <Sidebar />
      <div className="body-bgcolor" align="left">
        <div className='container'>
          <PageHero title='Products View' />
          <div className='clear'></div>
          <hr class="featurette-divider my-1" />
          <Heading heading="TITLE" info="" />
        </div>
      </div>
      </>
    )
  }
}

export default Checkout;