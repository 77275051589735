import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faMinus, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import 'react-toastify/dist/ReactToastify.css';
import ReactPageScroller from 'react-page-scroller';





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
    <App />

    

);
