import React, { Component } from 'react';
import IMAGES from '../assets';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight, faCartPlus, faChevronDown, faCheck, faEye, faCheckCircle } from '@fortawesome/free-solid-svg-icons';




class Product2 extends Component {
    render() {
        return (
            <div>
                <div class="card">
                    <img src={IMAGES.template3} className="img-fluid" />
                    <div class="card-body">
                        <h5>Autumn Design</h5>
                        <p class="">Some quick example text to build on the card title.</p>
                        <a href="/pricing" class="btn btn-primary pull-right"><FontAwesomeIcon icon={faCheck} /> select</a>
                        <Link to="/website/1" target="_blank" class="btn btn-warning pull-left"><FontAwesomeIcon icon={faEye} /> Preview</Link>
                    </div>
                </div>
<br/>
                <div class="card">
                    <img src={IMAGES.template4} className="img-fluid" />
                    <div class="card-body">
                       <h5>Winter Design</h5>
                        <p class="">ipsum suspendisse ultrices gravida.</p>
                        <a href="/pricing" class="btn btn-primary pull-right"><FontAwesomeIcon icon={faCheck} /> select</a>
                        <Link to="/website/1" target="_blank" class="btn btn-warning pull-left"><FontAwesomeIcon icon={faEye} /> Preview</Link>
                    </div>
                </div>

            </div>

        )
    }
}

export default Product2;