import React, { Component } from 'react';
import '../assets/html.css';
import PageHero from './PageHero';
import Heading from '../components/Heading';
import { Navbar, Sidebar, Footer } from '../components';
import { Button, Form, Card } from 'react-bootstrap';
import Product from "../components/Product";
import Product2 from "../components/Product2";
import Product3 from "../components/Product3";
import { Link } from 'react-router-dom';
import PickDesign from '../components/PickDesign';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight, faCartPlus, faChevronDown, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';


class SignIn extends Component {
  render() {
    return (
      <>
       <Navbar />
      <Sidebar />
      <div class="body-bgcolor">
        <div className="container" align="left">
          <PageHero title='SignIn' />
          <div className='clear'></div>
          <hr class="featurette-divider my-1" />
          <Heading heading="Pick a Design" catchy=" you like." info="Design 2/3"/>
          <div className="container">
          <PickDesign/>
          </div>
        </div>
      </div>
      </>
    )
  }
}

export default SignIn;