
export const Links = [
    {
      id: 1,
      text: '',
      url: '/',
    },
    // {
    //   id: 2,
    //   text: 'Features',
    //   url: '/AboutMe',
    // },
    // {
    //   id: 3,
    //   text: 'Programes',
    //   url: '/website',
    // },
    // {
    //   id: 10,
    //   text: 'Collab',
    //   url: '/signUp',
    //   },
  
    // {
    //   id: 5,
    //   text: 'Collab',
    //   url: '/contact',
    // },
    // {
    //   id: 7,
    //   text: 'Faqs',
    //   url: '/contact',
    // },
 
    // {
    // id: 9,
    // text: 'Contact',
    // url: '/signUp',
    // },
  ]
