import React, { useState } from 'react';
import '../assets/html.css';
import PageHero from './PageHero';
import Heading from "../components/Heading";
import { Button, Form, Card } from 'react-bootstrap';
import { Navbar, Sidebar, Footer } from '../components';
import RegisterClient from '../components/Registration';
import axios from "axios"


const Signup = () => {

  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="body-bgcolor">
        <div className="container" align="left">
          <PageHero title='Signup' />
          <div className='clear'></div>
          <hr class="featurette-divider my-1" />
          <Heading heading="Don't have an" catchy=" account?" info="Sign Up 1/3" />
          <div className="container">
            <div className="row">
              <div className="col w-50">
               
              </div>
            </div>
            <div className='col-md-6'></div>
          </div>
        </div>
        <div className="container">
          <RegisterClient />
                  </div>
      </div>
    </>
  )
}

export default Signup;