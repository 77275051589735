import React, { Component } from 'react';
import '../assets/html.css';
import PageHero from './PageHero';
import Heading from '../components/Heading';
import { Navbar, NavbarLogin, Sidebar, Footer, } from '../components';
import { Button, Form, Card } from 'react-bootstrap';
import Product from "../components/Product";
import Product2 from "../components/Product2";
import Product3 from "../components/Product3";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IMAGES from '../assets';
import { faChevronCircleRight, faMinus, faChevronDown, faCheck, faEye, faCheckCircle, faMailBulk, faMessage, faUser, faMailForward } from '@fortawesome/free-solid-svg-icons';


class EmailPre extends Component {
  render() {
    return (
      <>
        <NavbarLogin />
        <Sidebar />
        <div class="body-bgcolor">
          <div className="container" align="left">
          <div class="col-9 mt-1">
                <div class="list-group" id="list-tab" role="tablist">
                  <Link to="/UserPanel" class="list-group-item list-group-item-action" id="list-messages-list" data-toggle="list" href="#list-messages" role="tab" aria-controls="messages"><FontAwesomeIcon icon={faUser} size="1x" />&nbsp;My Account</Link>
                  <Link to="/SMS" class="list-group-item list-group-item-action" id="list-messages-list" data-toggle="list" href="#list-messages" role="tab" aria-controls="messages"><FontAwesomeIcon icon={faMessage} size="1x" />&nbsp;SMS</Link>
                  <Link to="/Email" class="list-group-item list-group-item-action" id="list-messages-list" data-toggle="list" href="#list-messages" role="tab" aria-controls="messages"><FontAwesomeIcon icon={faMailBulk} size="1x" />&nbsp;Email</Link>
                </div>
              </div>
            <div class="row">
             
              <div class="col-12">
                <div class="my-1 p-1 card">
                  <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="list-home" aria-labelledby="list-home-list">
                      <div class="row">
                        <div className='col-md-12'>
                          <h1 class="display-3 text-primary">Email Preview </h1>
                          <hr className='mb-1'></hr>
                          <medium class="form-text">Total Remaining: <i className='text-success'>100 Email credits.</i></medium>
                          <form>
                          <button type="submit" class="btn btn-primary mt-1"><FontAwesomeIcon icon={faMailBulk} size="1x" /> Send </button>

                          </form>
                        </div>

                      </div>


                    </div>
                    <div class="tab-pane fade" id="list-profile" role="tabpanel" aria-labelledby="list-profile-list">...</div>
                    <div class="tab-pane fade" id="list-messages" role="tabpanel" aria-labelledby="list-messages-list">...</div>
                    <div class="tab-pane fade" id="list-settings" role="tabpanel" aria-labelledby="list-settings-list">...</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className='row' style={{background:"#ededed"}}>
          <div className='my-1' style={{ borderRadius: "15px", background: "#fff", padding: "20px", width: "650px", margin: "0 auto", }}>
            <div class="card" style={{}}>
              <div class="card-body">
                <Link to='/' className='pull-right'>
                  <img src={IMAGES.logo} alt='Website A1 logo'  />
                </Link>
                <div className='clear'></div>
                <hr />
                <div style={{ lineHeight: "35px" }}>

                  Hello,<br />

                  <br />
                  GDP calculations are getting an upgrade.<br />


                  <br />
                  The Ministry of Statistics and Programme Implementation has established a committee to oversee the revision of the base year for national accounts. The effort aims to modernise accounting practices, ensuring they reflect current economic realities and global standards.
                  <br />
                  <br />

                  In other news, the taxman has come knocking.
                  <br />
                  <br />


                  Zomato received a GST demand notice of Rs 9.45 crore from the Assistant Commissioner of Commercial Taxes, Karnataka. The company plans to file an appeal in response to the tax notice.

                  <br /><br />

                  Meanwhile, Ola Electric is gearing up to adopt solid-state battery technology. CEO Bhavesh Aggarwal revealed that the company was in the early stages of experimentation and is expected to use its own cells in its electric two-wheelers soon.

                  <br /><br />

                  The company’s Li-ion battery cell has received certification from the Bureau of Indian Standards. It is expected to significantly reduce the production costs of the electric scooters.

                  <br /><br />

                  Lastly, as India takes home another glory with the T20 World Cup, and the Rs 125 crore prize money, it becomes only the third team to bring back the trophy after West Indies and England.

                  <br /><br />

                  India scripted many records on the way to its 2024 win, including notching an unbeaten streak throughout the tournament.



                  In today’s newsletter, we will talk about

                  Reducing waste in fruit supply chain
                  Powering homes with clean energy
                </div>
                <div>
                  <hr /><div class="ovals" style={{ textAlign: "center" }}>
                    <div><br />Have a question? Visit our <Link to='/' className='text-success'>Website</Link> <br /><br />
                    </div>
                    <Link to='/support'>Home</Link> |   <Link to='/terms' >Services</Link> | <Link to='/privacy'>About</Link> | <Link to='/contact'>Contact</Link>
                    <h5 class="footer-copy my-1" style={{ textAlign: "center" }}>&copy; Copyright {new Date().getFullYear()} Website<medium className='text-success'>On</medium></h5>
                  </div>
                </div>
              </div>
            </div>

          </div>




        </div>
      </>
    )
  }
}

export default EmailPre;