import React, { Component, } from 'react';
import { Link } from 'react-router-dom';
import { Links } from '../utils/constants';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faRightToBracket, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import logo from '../assets/logo.png';

class Sidebar extends Component {
  render() {
    function close() {
      document.getElementById("sidebar").style.display = "none";
    }
    function open() {
      document.getElementById("sidebar").style.display = "block";
    }
    const sidebar = {
      color: "white",
      backgroundColor: "#ffeeac",
      fontFamily: "Arial",
      width: "100%",
      height: "100%",
      zIndex: "999",
      position: "fixed",
      top: "0",
      left: "0",
      display: "none",
    };
    
    return (
      <div className='wrapper'>
        <div className='sidebar' id="invisible">
          <div className='sidebar-nav'>
            <div className='logo pull-left m-1'>
              <Link to='/'>
                <img src={logo} alt='Website A1 logo' />
              </Link>
            </div>
            <ul className='nav pull-right mx-1'>          
              <li className="nav-item">
                <Link to="/" class="btn btn-primary" onClick={open}><FontAwesomeIcon icon={faBars} size={70} /></Link>
              </li>
            </ul>
          </div>
        </div>
        <div id="sidebar" style={sidebar} className="#animated #slideInRight">
          <div className='logo pull-left m-1'>
            <Link to='/' onClick={close}>
              <img src={logo} alt='Website A1 logo' />
            </Link>          
          </div>
          <a className='pull-right m-1' onClick={close} href="#"><FontAwesomeIcon icon={faTimes} size='3x' color="black" /></a>
          <Link to="/signup" class="btn btn-primary pull-right mt-1" onClick={close}><FontAwesomeIcon icon={faRightToBracket} />&nbsp;Signup</Link>
          <div className='clear'></div>
          <ul style={{ overflowX:"hidden", overflowY:"scroll",}}>
            {Links.map((link) => {
              const { id, text, url } = link;
              return <li key={id} className="py-1">
                <Link to={url} className='' onClick={close}>{text}</Link>
              </li>
            })}           
          </ul>
        </div>
      </div>
    )
  }
}

export default Sidebar;
