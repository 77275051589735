const IMAGES = {
    PrakashGoud: require('./PrakashGoud.jpg'),
    blocks: require('./blocks.png'),
    change: require('./change.PNG'),
    footLogo: require('./footer-logo.PNG'),
    logo: require('./logo.png'),
    formhandling: require('./formhandling.png'),
    responsive: require('./responsive.png'),
    hosting: require('./hosting.png'),
    support: require('./support.png'),
    gallery: require('./gallery.png'),
    custom: require('./custom.png'),
    responsiveconflict: require('./responsive-issues.jpg'),
    works: require('./works.png'),
    template0: require('./Template1.JPG'),
    template2: require('./Template2.png'),
    template3: require('./Template13.JPG'),
    template4: require('./Template4.jpg'),
    template5: require('./Template9.jpg'),
    template1: require('./Template10.jpg'),
    summer: require('./summer.jpg'),
    template6: require('./summer-thum.PNG'),
}

export default IMAGES;