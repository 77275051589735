import React, { Component } from 'react';
import PageHero from './PageHero';
import Heading from "../components/Heading";
import { Navbar, Sidebar, Footer } from '../components';


class Terms extends Component {
  render() {
    return (
      <>  
      <Navbar />
      <Sidebar />
      <div className="body-bgcolor" align="left">
        
        <div className='container'>
          <PageHero title='Terms' />
          <div className='clear'></div>
          <hr class="featurette-divider my-1" />
          <Heading heading="Terms" info="" />

          <div class="row">
          <div className='col-md-12 mb-1'>
          Welcome to our terms and conditions page. This page outlines the terms and conditions that govern the use of our website and the services we offer. By using our website and/or engaging our services, you agree to these terms and conditions.<br/>
<br/>
<strong>Intellectual Property: </strong>
All content, including text, images, graphics, and logos, on our website and in our services is the property of our company or our respective 
licensors and is protected by copyright laws. You may not copy, distribute, reproduce, or modify any content on our website or in our services without our written consent.<br/><br/>
<strong>Client Responsibilities: </strong>
As our client, you agree to provide us with accurate and complete information necessary for us to deliver our services. You also agree to 
cooperate with us and provide timely feedback and approvals throughout the project timeline.
<br/><br/>
<strong>Payment and Fees: </strong>
Payment for our services is due according to the payment schedule agreed upon between our company and the client. Any additional fees or 
costs associated with the project will be communicated to the client and agreed upon before work is started.<br/><br/>
<strong>Project Timeline: </strong>
We will work with the client to establish a project timeline and deliverables. Any delays or changes in the project timeline due to 
circumstances beyond our control may result in delays in the delivery of the project.
<br/><br/>
<strong>Website Hosting and Maintenance: </strong>
We offer website hosting and maintenance services to our clients. These services are subject to separate terms and conditions 
that will be provided to the client upon request.
<br/><br/>
<strong>Liability and Limitations: </strong>
Our company is not liable for any damages or losses resulting from the use of our website or services, including but not limited 
to direct, indirect, or consequential damages. Our liability is limited to the fees paid by the client for the services provided.          
<br/><br/>
<strong>Termination of Services: </strong>
Either party may terminate the services provided by us at any time for any reason. If the client terminates the services before 
completion, the client will be responsible for payment for services rendered up to that point.            
<br/><br/>
<strong>Governing Law: </strong>
These terms and conditions are governed by the laws of the jurisdiction in which our company is located.        
<br/>
If you have any questions or concerns regarding these terms and conditions, please do not hesitate to contact us.
</div>
</div>
</div>
      </div>
      </>
    )
  }
}

export default Terms;