import React, { Component } from 'react';
import PageHero from './PageHero';
import { Navbar, Sidebar, Footer } from '../components';
import Heading from "../components/Heading";

class Support extends Component {
  render() {
    return (
      <>    
      <Navbar />
     <Sidebar />
      <div className="body-bgcolor" align="left">
        <div className='container'>
          <PageHero title='Support' />
          <div className='clear'></div>
          <hr class="featurette-divider my-1" />
          <Heading heading="Help & " catchy="Support" info="feel free to call." />

<div class="row">
          <div className='col-md-12 mb-1'>
          Welcome to our support page for website designing services! We are here to assist you in all aspects of website design, development, and maintenance.

Frequently Asked Questions (FAQs):
<br/><br/>
<strong>Q: What is website design?</strong><br/>
A: Website design refers to the process of creating the visual and functional aspects of a website. This includes layout, color scheme, typography, and user interface design.
<br/><br/>
<strong>Q: How much does it cost to design a website?</strong><br/>
A: The cost of designing a website varies depending on the complexity of the design, the number of pages, and the features required. Please contact us to discuss your project and get a customized quote.
<br/><br/>
<strong>Q: How long does it take to design a website?</strong><br/>
A: The time it takes to design a website depends on the complexity of the project and the client's requirements. A simple website can take a few weeks, while a more complex website may take several months. We work closely with our clients to set realistic timelines and keep them informed of our progress.
<br/><br/>
<strong>Q: What is responsive design?</strong><br/>
A: Responsive design is an approach to website design that ensures the website can be viewed and used effectively on different devices, such as desktops, laptops, tablets, and smartphones. A responsive website adjusts its layout and content to fit the screen size of the device it is being viewed on.
<br/><br/>
<strong>Q: Do you provide website maintenance services?</strong><br/>
A: Yes, we offer website maintenance services to ensure your website stays up to date, secure, and functional. Our maintenance services include regular updates, backups, and security checks.
<br/><br/>
<strong>Q: Can you help with website content creation?</strong><br/>
A: Yes, we can help with website content creation, including copywriting, image selection, and video production. We work with our clients to understand their brand voice and create content that resonates with their target audience.
<br/><br/>
<strong>Q: What is SEO?</strong><br/>
A: SEO stands for Search Engine Optimization, which refers to the process of optimizing a website's content and structure to improve its ranking in search engine results pages (SERPs). SEO involves techniques such as keyword research, on-page optimization, and link building.
<br/><br/>
<strong>Q: Do you provide SEO services?</strong><br/>
A: Yes, we offer SEO services to help our clients improve their website's visibility and ranking in search engine results pages. Our SEO services include keyword research, on-page optimization, and link building.
<br/><br/>
If you have any other questions or concerns, please do not hesitate to contact us. We are always here to help you with your website design needs.
            </div>
            </div>
        </div>
      </div>
      </>
    )
  }
}

export default Support;