import React, { Component } from 'react';
import '../assets/html.css';
import PageHero from './PageHero';
import Heading from '../components/Heading';
import { Navbar, NavbarLogin, Sidebar, Footer } from '../components';
import { Button, Form, Card } from 'react-bootstrap';
import Product from "../components/Product";
import Product2 from "../components/Product2";
import Product3 from "../components/Product3";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IMAGES from '../assets';
import { faChevronCircleRight, faMinus, faChevronDown, faCheck, faEye, faCheckCircle, faMailBulk, faMessage, faUser, faMailForward } from '@fortawesome/free-solid-svg-icons';
import HTMLeditor from '../components/HTMLeditor';

class Email extends Component {
  render() {
    return (
      <>
        <NavbarLogin />
        <Sidebar />
        <div class="body-bgcolor">
          <div className="container" align="left">
          <div class="col-9 mt-1">
                <div class="list-group" id="list-tab" role="tablist">
                <Link to="/UserPanel" class="list-group-item list-group-item-action" id="list-messages-list" data-toggle="list" href="#list-messages" role="tab" aria-controls="messages"><FontAwesomeIcon icon={faUser} size="1x" />&nbsp;My Account</Link>
                  <Link to="/SMS" class="list-group-item list-group-item-action" id="list-messages-list" data-toggle="list" href="#list-messages" role="tab" aria-controls="messages"><FontAwesomeIcon icon={faMessage} size="1x" />&nbsp;SMS</Link>
                  <Link to="/Email" class="list-group-item list-group-item-action" id="list-messages-list" data-toggle="list" href="#list-messages" role="tab" aria-controls="messages"><FontAwesomeIcon icon={faMailBulk} size="1x" />&nbsp;Email</Link>
                </div>
              </div>
            <div class="row">
            <div class="col-12">
                <div class="my-1 p-1 card">

                  <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="list-home" aria-labelledby="list-home-list">
                      <div class="row">
                        <div className='col-md-12'>
                          <h1 class="display-3 text-primary">Email Marketing </h1>
                          <hr className='mb-1'></hr>
                          <medium class="form-text">Total Remaining: <i className='text-success'>100 Email credits.</i></medium>
                          <form>
                            <div class="form-group">
                            <input name="name2" autoComplete='off' type="text" placeholder='info@suryaexports.com' className="form-control p-1 mt-1 w-100"></input>
                              <input name="name2" autoComplete='off' type="text" placeholder='user1@gmail.com, user2@gmail.com' className="form-control p-1 mt-1 w-100"></input>
                              <small id="emailHelp" class="form-text text-muted">We'll never share your numbers with anyone else.</small>
                              <div class="card">

                                <div class="card-body">
                                  <HTMLeditor />

                                </div>
                              </div>
                              <button type="submit" class="btn btn-primary mt-1"><FontAwesomeIcon icon={faMailBulk} size="1x" /> Send Mails </button> &nbsp;
                              <Link to="/Emailpre" class="btn btn-success mt-1"><FontAwesomeIcon icon={faEye} size="1x" /> Email Preview </Link>

                            </div>

                          </form>
                        </div>

                      </div>


                    </div>
                    <div class="tab-pane fade" id="list-profile" role="tabpanel" aria-labelledby="list-profile-list">...</div>
                    <div class="tab-pane fade" id="list-messages" role="tabpanel" aria-labelledby="list-messages-list">...</div>
                    <div class="tab-pane fade" id="list-settings" role="tabpanel" aria-labelledby="list-settings-list">...</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }
}

export default Email;