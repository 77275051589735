import React, { Component } from 'react';
import { Link } from 'react-router-dom';



class ErrorPage extends Component {
    render() {
        return (
             <>
            <div className='container' style={{ minHeight: "300px" }}>
                <h1 className='display-1 white my-1'><strong>404</strong></h1>
                <p className='white my-1'>Sorry, The page you tried cannot be found.</p>
                <Link to="/" className='btn btn-warning my-1'>Back Home</Link>
            </div>
            </>
        )
    }
}

export default ErrorPage;