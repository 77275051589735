import React, { Component } from 'react';
import '../assets/html.css';
import PageHero from './PageHero';
import Heading from '../components/Heading';
import { Navbar, Sidebar, Footer } from '../components';
import { Button, Form, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';



class SignIn extends Component {
  render() {
    return (
      <>
       <Navbar />
      <Sidebar />
      <div class="body-bgcolor">
        <div className="container" align="left">
          <PageHero title='SignIn' />
          <div className='clear'></div>
          <hr class="featurette-divider my-1" />
          <Heading heading="Already have an" catchy=" account?" info="Sign In"/>
          <div className="container">
            <div className="row">
              <div className="col w-50">

                <Card className="my-1 p-1 card w-50 mx-auto">
                  <Form className='pull-left form-floating'>
                    <Form.Group className="" controlId="formBasicEmail">
                      <Form.Control type="text" placeholder="Enter Mobile" className="p-1 mt-1 w-100" />
                      <Form.Text className="text-muted">
                        We'll never share your number with anyone else.
                      </Form.Text>
                    </Form.Group>
                    <Form.Group className="" controlId="formBasicPassword">
                      <Form.Control type="password" placeholder="Password" className="p-1 mt-1 w-100" />
                    </Form.Group>
                    <br/>
                    <p className="text-primary">
                    <Link to="/PickDesign" class="pull-right"> Forgot Password?</Link>

                      </p>
                    <Button variant="primary" type="submit" className='w-100 mt-1 py-1'>
                      Submit
                    </Button>
                  </Form>
                </ Card>

              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
}

export default SignIn;