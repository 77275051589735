import React, { Component } from 'react';
import PageHero from './PageHero';
import Heading from "../components/Heading";
import { Button, Form, Card } from 'react-bootstrap';
import { Navbar, NavbarLogin, Sidebar, Footer } from '../components';




class PreSupport extends Component {
  render() {
    return (
      <>    
       <NavbarLogin />
      <Sidebar />
      <div className="body-bgcolor" align="left">
        <div className='container'>
          <PageHero title='Support' />
          <div className='clear'></div>
          <hr class="featurette-divider my-1" />
          <Heading heading="Support" info="Have any queries?" />
          <div class="row g-5">
            <div className='col-md-7'>
           
              <Card className="p-1 mb-1 card w-100">
              <div class="row">
              <div class="col-md-12"> <input type="text" placeholder="Company Name" className="form-control my-1" /></div>
              <div class="col-md-12"> <input type="text" placeholder="Mobile" className="form-control  my-1" /></div>
              <div class="col-md-12"> <input type="text" placeholder="Email" className="form-control  my-1" /></div>
              <div class="col-md-12"> <textarea type="text" rows="4" cols="50" placeholder="your Query Goes Here..."  className="form-control  my-1"/></div>
              <div class="col-md-12"> <button class="btn btn-primary" role="button" href="/website">Submit</button></div>
              <br/><br/>
              <div className='col-md-12'> 
             <small class="text-muted">
                      We will contact you shortly, 
                      <br/>
                      Note: We'll never share your number with anyone else.
                    </small></div>
            </div>
                
                
              </ Card>

            </div>
            <div class="col-md-5 col-lg-4 order-md-last">

              <ul class="list-group mb-1">
                <li class="list-group-item d-flex justify-content-between lh-sm">
                  <div>
                  
                    <span class="text-muted">🫵 You are our priority. <br/><br/>We'll give you a call-back right away so you can get back to what matters most.
                    <br/> 
                    <br/> We're here to help. 🤝</span>
                    <br/>
                  </div>
                </li>             
               </ul>
             


            </div>
          </div>
        </div>

      </div>  
      </>
    )
  }
}

export default PreSupport;