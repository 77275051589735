import React, { Component } from 'react';
import '../assets/html.css';
import PageHero from './PageHero';
import Heading from '../components/Heading';
import { Navbar, NavbarLogin, Sidebar, Footer } from '../components';
import { Button, Form, Card } from 'react-bootstrap';
import Product from "../components/Product";
import Product2 from "../components/Product2";
import Product3 from "../components/Product3";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IMAGES from '../assets';
import { faChevronCircleRight, faMinus, faChevronDown, faCheck, faEye, faCheckCircle, faMailBulk, faMessage, faUser, faPhone,  } from '@fortawesome/free-solid-svg-icons';


class SignIn extends Component {
  render() {
    return (
      <>
       <NavbarLogin />
      <Sidebar />
      <div class="body-bgcolor">
        <div className="container" align="left">
        <div class="col-9 mt-1">
                <div class="list-group" id="list-tab" role="tablist">
                <Link to="/UserPanel" class="list-group-item list-group-item-action" id="list-messages-list" data-toggle="list" href="#list-messages" role="tab" aria-controls="messages"><FontAwesomeIcon icon={faUser} size="1x" />&nbsp;My Account</Link>
                  <Link to="/SMS" class="list-group-item list-group-item-action" id="list-messages-list" data-toggle="list" href="#list-messages" role="tab" aria-controls="messages"><FontAwesomeIcon icon={faMessage} size="1x" />&nbsp;SMS</Link>
                  <Link to="/Email" class="list-group-item list-group-item-action" id="list-messages-list" data-toggle="list" href="#list-messages" role="tab" aria-controls="messages"><FontAwesomeIcon icon={faMailBulk} size="1x" />&nbsp;Email</Link>
                </div>
              </div>
        <div class="row">
 
  <div class="col-12">
  <div class="my-1 p-1 card">

    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list">
      <h1 class="display-3 text-primary">Account Info</h1>
<hr className='mb-1'></hr>
      <div class="row"> <div className='col-md-6'>
        
            <div class="card">
                    <img src={IMAGES.summert} className="img-fluid" />
                    <div class="card-body">
                        <h5>Summer Design</h5>
                    </div>
                </div>
            </div>
            <div className='col-md-6'>  
              
            <medium class="text-muted"> 
            <p>Company Name : <strong className='text-primary'><i>Surya consultacy</i></strong></p>
            <p>Domain : <u><Link to="#" >www.suryaexports.com</Link></u></p>
            <p>Phone : <strong className='text-primary'><i>+91-9505226507</i></strong></p>
            <p>Email : <strong className='text-primary'><i>prakashgoud2@gmail.com</i></strong></p>
        <p>Plan Renewal Date : 10/9/2027</p>
        <p>Domain Renewal Date : 5/9/2027</p>
        <p>   Live Status : <Link to="https://ovalstech.in/" target="_blank" class="btn-sm btn-success">Online</Link>  <Link to="https://ovalstech.in/" target="_blank" class="btn-sm btn-secondary">Offline</Link> 
        </p>

        </medium>       
            
           <div className='clear'></div>
         
            </div>
    </div>
      
        
      </div>
      <br/>
      <h1 class="display-3 text-primary">Want more websites?</h1>
      <hr className='mb-1'></hr>
      <div className='row'>
        
             <div className='col-md-6 mb-1'>
                <Product3 />
              </div>
              <div className='col-md-6 mb-1'>
                <Product2 />

              </div>
              <div className='col-md-6 mb-1'>
                <Product />

              </div>
              <div className='col-md-6 mb-1'>

                <Product />
              </div>
              <div className='col-md-6 mb-1'>

                <Product />
              </div>
              <div className='col-md-6 mb-1'>
                <Product />
              </div>
            </div>
      <div class="tab-pane fade" id="list-profile" role="tabpanel" aria-labelledby="list-profile-list">...</div>
      <div class="tab-pane fade" id="list-messages" role="tabpanel" aria-labelledby="list-messages-list">...</div>
      <div class="tab-pane fade" id="list-settings" role="tabpanel" aria-labelledby="list-settings-list">...</div>
    </div>
    </div>
  </div>
</div>
        </div>
      </div>
      
      </>
    )
  }
}

export default SignIn;