import React, { Component } from 'react';
import IMAGES from '../assets';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight, faCartPlus, faChevronDown,faCheck,faEye } from '@fortawesome/free-solid-svg-icons';




class Product extends Component {
    render() {
        return (
            <div>
                <div class="card">
                    <img src={IMAGES.template6} className="img-fluid" />
                    <div class="card-body">
                       <h5>Winter Design</h5>
                        <p class="">ipsum suspendisse ultrices gravida.</p>
                        <a href="/pricing" class="btn btn-primary pull-right"><FontAwesomeIcon icon={faCheck} /> select</a>
                        <Link to="/website/1" target="_blank" class="btn btn-warning pull-left"><FontAwesomeIcon icon={faEye} /> Preview</Link>
                    </div>
                </div>
                <br/>

                <div class="card">
                    <img src={IMAGES.template0} className="img-fluid" />
                    <div class="card-body">
                       <h5>Winter Design</h5>
                        <p class="">ipsum suspendisse ultrices gravida.</p>
                        <a href="/pricing" class="btn btn-primary pull-right"><FontAwesomeIcon icon={faCheck} /> select</a>
                        <Link to="/website/1" target="_blank" class="btn btn-warning pull-left"><FontAwesomeIcon icon={faEye} /> Preview</Link>
                    </div>
                </div>

                

            </div>

        )
    }
}

export default Product;