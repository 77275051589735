import React, { Component } from 'react';
import PageHero from './PageHero';
import Heading from "../components/Heading";
import { Navbar, Sidebar, Footer } from '../components';



class Privacy extends Component {
  render() {
    return (
      <>
        <Navbar />
        <Sidebar />
        <div className="body-bgcolor" align="left">
          <div className='container'>
            <PageHero title='Privacy' />
            <div className='clear'></div>
            <hr class="featurette-divider my-1" />
            <Heading heading="Privacy" info="" />
            <div class="row">
          <div className='col-md-12 mb-1'>
          Welcome to our privacy policy page. We understand the importance of privacy and are committed to protecting the personal information of our 
          clients and website visitors. This privacy policy outlines the types of personal information we collect, how we use it, and how we protect it.
          <br/><br/>
          <strong>Information Collection:</strong><br/>
We may collect personal information from clients and website visitors, including but not limited to name, email address, phone number, and payment 
information. We may also collect non-personal information such as IP addresses and browser information.
<br/><br/>
          <strong>
Use of Information:</strong><br/>
We use personal information to provide our services to clients, communicate with clients and website visitors, and improve our services. We may also 
use non-personal information for analytical and statistical purposes.
<br/><br/>
          <strong>
Information Sharing:</strong><br/>
We do not share personal information with third parties except when necessary to provide our services to clients or when required by law. We may share 
non-personal information with third parties for analytical and statistical purposes.
<br/><br/>
          <strong>
Security:</strong><br/>
We take reasonable measures to protect personal information from unauthorized access, disclosure, or misuse. We use industry-standard security measures
 such as encryption and secure servers to protect personal information.
 <br/><br/>
          <strong>
Cookies:</strong><br/>
We use cookies on our website to enhance user experience and for analytical and statistical purposes. Cookies are small text files that are stored on a 
user's device. Users may choose to disable cookies in their browser settings, but this may affect the functionality of our website.
<br/><br/>
          <strong>
Links to Third-Party Websites:</strong><br/>
Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites.
<br/><br/>
          <strong>
Children's Privacy:</strong><br/>
Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children under the age of 13.
<br/><br/>
          <strong>
Changes to Privacy Policy:</strong><br/>
We may update this privacy policy from time to time. Any changes will be posted on this page.
<br/><br/>
If you have any questions or concerns regarding our privacy policy, please do not hesitate to contact us.
            </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Privacy;