import React, { Component } from 'react';
import PageHero from './PageHero';
import Heading from "../components/Heading";
import PickDesign from '../components/PickDesign';
import { Navbar, Sidebar, Footer } from '../components';



class Website extends Component {
  render() {
    return (
      <>
        <Navbar />
            <Sidebar />
        <div className="body-bgcolor" align="left">

          <div className='container'>
            <PageHero title='Websites' />
            <div className='clear'></div>
            <hr class="featurette-divider my-1" />
            <Heading heading="Choose a " catchy="Design" info="Best suits you." />
            <PickDesign/>
          </div>
        </div>
      </>
    )
  }
}

export default Website;