import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight, faMinus, faChevronDown } from '@fortawesome/free-solid-svg-icons';

export class Heading extends Component {
  render() {
    return (
        <div class="" align="center">
        <div class="blue wow animated fadeInDown">
          <FontAwesomeIcon icon={faMinus} /></div>
        <h2 class="display-5 wow animated fadeInUp">{this.props.heading}<strong class="blue">{this.props.catchy}</strong></h2>
        <p class="mt-1 wow animated fadeInUp">
        {this.props.info}
        </p>
      </div>
    )
  }
}

export default Heading;