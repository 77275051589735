import React, { Component } from 'react';
import PageHero from './PageHero';
import Heading from "../components/Heading";
import Live from '../components/LiveMenu';
import { Navbar, Sidebar, Footer } from '../components';
import IMAGES from '../assets';





class LivePreview extends Component {  
  render() {
   
    return (
      <div  onload="scrollh()">
        <div>
          <Live />
        </div>
        <div style={{paddingTop:"82px"}} >
                  <img src={IMAGES.summer} className='livep'  id="iframe" frameborder="0" title="Wwebsita1 Theme"  width="100%"
        allowfullscreen="true" scrolling="auto" loading="eager"></img>
        </div>
      </div>
    )
  }
}

export default LivePreview;



