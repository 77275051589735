import React, { Component } from 'react'
import { Link } from 'react-router-dom';

const PageHero = ({ title }) => {
    return <div>
        <div className='pull-left mt-1'>
            <Link to="/">Home</Link> / {title}
        </div>
    </div>
}
export default PageHero;