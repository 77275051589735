import React, { Component } from 'react';
import PageHero from './PageHero';
import Heading from "../components/Heading";
import IMAGES from '../assets';
import { Navbar, Sidebar, Footer } from '../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faChevronCircleRight, faMinus, faChevronDown, faCheck, faEye, faCheckCircle } from '@fortawesome/free-solid-svg-icons';




class Plans extends Component {
  render() {
    return (
      <>
        <Navbar />
        <Sidebar />
        <div className="body-bgcolor" align="left">
          <div className='container'>
            <PageHero title='Pricing' />
            <div className='clear'></div>
            <hr class="featurette-divider my-1" />

            <div class="row row-cols-1 row-cols-md-3 = mb-2">
              <div className='col-md-4 mb-1'>
                <div class="card">
                  <img src={IMAGES.summert} className="img-fluid" />
                  <div class="card-body text-center">
                    <h5>Summer Design</h5>
                  </div>
                </div>
              </div>
              <div className='col-md-8 mb-1'>
                <p class="text-left">😎 Whoa, You've got excellent taste! That design is a winner.</p>
                <a href="#" class="btn btn-success pull-left mr-1"><FontAwesomeIcon icon={faCheckCircle} /></a>

                <Link to="/website/1" target="_blank" class="btn btn-warning pull-left"><FontAwesomeIcon icon={faEye} /> Live Preview</Link>
                <div className='clear'></div>
                <p class="text-left mt-1">"Your website will radiate a warm and inviting energy with its vibrant yellow backdrop and 
                  a welcoming brown centerpiece featuring your company logo,
                  This design is not only visually engaging but also conveys optimism and approachability.




                </p>
              </div>
              <div className='col-12'>
                <hr class="featurette-divider my-1" />
                <Heading heading="Best" catchy=" Plan" info="in the market." />
              </div>
              <div className='row'>
              <div className='col-md-4 mb-1'>

                <div class="card rounded-3 shadow-sm box-shadow">
                  <div class="card-header bg-info">
                    <h4 class="my-0 fw-normal white">Individual</h4>
                  </div>
                  <div class="card-body">
                    <div><h1 class="card-title pricing-card-title pull-left">₹333</h1><small class="text-muted fw-light">/ Month</small></div>
                    <div className='clear'></div>
                    <small class="text-muted fw-light">Per year 3,999 INR</small>
                    <button type="submit" class="btn-lg btn-block btn-outline-primary mt-1">Select</button>
                    <div className='clear'></div>
                    <ul class="list-unstyled">
                      <li>Free domain for 1 year.</li>
                      <li>Hosting for 1 year.</li>
                      <li>Unlimited SSD disk*</li>
                      <li>1 free email</li>
                      <li>customizable web forms</li>
                      <li>Call Support</li>
                      <li>SEO Friendly Design</li>
                      <li> Mobile Responsive Design</li>
                      <li> Integrated Google Maps</li>
                      <li>Integrated Google Analytics</li>
                      <li>Design Change every year</li>
                      <li>100 SMS Credits</li>
                      <li>100 HTML In-App Mails</li>
                      <li>Image Gallery Upto 5 Images</li>
                      <li>Upto 5 Pages</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-md-4 mb-1'>

                <div class="card rounded-3 shadow-sm box-shadow">
                  <div class="card-header bg-success">
                    <h4 class="my-0 fw-normal white">Professional</h4>
                  </div>
                  <div class="card-body">
                    <div><h1 class="card-title pricing-card-title pull-left">₹383</h1><small class="text-muted fw-light">/ Month</small></div>
                    <div className='clear'></div>
                    <small class="text-muted fw-light">Per year 4,599 INR</small>
                    <button type="submit" class="btn-lg btn-block btn-outline-primary mt-1">Select</button>
                    <div className='clear'></div>
                    <ul class="list-unstyled">
                      <li>Free domain for 1 year.</li>
                      <li>Hosting for 1 year.</li>
                      <li>Unlimited SSD disk*</li>
                      <li>3 Free email</li>
                      <li>customizable web forms</li>
                      <li>Call Support</li>
                      <li>SEO Friendly Design</li>
                      <li>Mobile Responsive Design</li>
                      <li>Integrated Google Maps</li>
                      <li>Integrated Google Analytics</li>
                      <li>Design Change every year</li>
                      <li>300 SMS Credits</li>
                      <li>300 HTML In-App Mails</li>
                      <li>Image Gallery Upto 15 Images</li>
                      <li>Upto 10 Pages</li>
                    </ul>

                  </div>
                </div>
              </div>
              <div className='col-md-4 mb-1'>
                <div class="card rounded-3 shadow-sm box-shadow">
                  <div class="card-header bg-primary">
                    <h4 class="my-0 fw-normal white">Business</h4>
                  </div>
                  <div class="card-body">
                    <div><h1 class="card-title pricing-card-title pull-left">₹499</h1><small class="text-muted fw-light">/ Month</small></div>
                    <div className='clear'></div>
                    <small class="text-muted fw-light">Per year 5,999 INR</small>
                    <button type="submit" class="btn-lg btn-block btn-outline-primary mt-1">Select</button>
                    <div className='clear'></div>

                    <ul class="list-unstyled">
                      <li>Free domain for 1 year.</li>
                      <li>Hosting for 1 year.</li>
                      <li>Unlimited SSD disk*</li>
                      <li>5 Free email</li>
                      <li>customizable web forms</li>
                      <li>Call Support</li>
                      <li>SEO Friendly Design</li>
                      <li> Mobile Responsive Design</li>
                      <li> Integrated Google Maps</li>
                      <li>Integrated Google Analytics</li>
                      <li>Design Change every year</li>
                      <li>600 SMS Credits</li>
                      <li>600 HTML In-App Mails</li>
                      <li>Image Gallery Upto 25 Images</li>
                      <li>Upto 15 Pages</li>


                    </ul>


                  </div>
                </div>
              </div>
            </div>



            </div>





            {/* <table class="">
            <thead>
              <tr>
                <th style="width: 34%;"></th>
                <th style="width: 22%;">Free</th>
                <th style="width: 22%;">Pro</th>
                <th style="width: 22%;">Enterprise</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="" class="text-start">Public</th>
                <td><a href="#check"></a></td>
                <td><a href="#check"></a></td>
                <td><a href="#check"></a></td>
              </tr>
              <tr>
                <th scope="" class="text-start">Private</th>
                <td></td>
                <td><a href="#check"></a></td>
                <td><a href="#check"></a></td>
              </tr>
            </tbody>

            <tbody>
              <tr>
                <th scope="" class="text-start">Permissions</th>
                <td><a href="#check"></a></td>
                <td><a href="#check"></a></td>
                <td><a href="#check"></a></td>
              </tr>
              <tr>
                <th scope="" class="text-start">Sharing</th>
                <td></td>
                <td><a href="#check"></a></td>
                <td><a href="#check"></a></td>
              </tr>
              <tr>
                <th scope="" class="text-start">Unlimited members</th>
                <td></td>
                <td><a href="#check"></a></td>
                <td><a href="#check"></a></td>
              </tr>
              <tr>
                <th scope="" class="text-start">Extra security</th>
                <td></td>
                <td></td>
                <td><a href="#check"></a></td>
              </tr>
            </tbody>
          </table> */}

          </div>
        </div>
      </>
    )
  }
}

export default Plans;