import React, { Component } from 'react';
import PageHero from './PageHero';
import Heading from "../components/Heading";
import { Button, Form, Card } from 'react-bootstrap';
import { Navbar, Sidebar, Footer } from '../components';




class Checkout extends Component {
  render() {
    return (
      <>    
       <Navbar />
      <Sidebar />
      <div className="body-bgcolor" align="left">
        <div className='container'>
          <PageHero title='Checkout' />
          <div className='clear'></div>
          <hr class="featurette-divider my-1" />
          <Heading heading="Checkout" info="place your order." />
          <div class="row g-5">
            <div className='col-md-7'>
           
              <Card className="p-1 mb-1 card w-100">
              <div class="row">
              <div class="col-md-6"> <input type="text" placeholder="Company Name" className="form-control my-1" /></div>
              <div class="col-md-6"> <input type="text" placeholder="Mobile" className="form-control  my-1" /></div>
              <div class="col-md-12"> <input type="text" placeholder="Email" className="form-control  my-1" /></div>
              <div class="col-md-12"> 
              <select className="form-control  my-1" id="country">
                <option value="">Select Design</option>
                <option>Summer</option>
                <option>Winter</option>
                <option>Warm</option>
                <option>Breeze</option>
                <option>Autumn</option>
                <option>Drizzly</option>
              </select>
              </div>
              <div class="col-md-12"> 
              <select className="form-control  my-1" id="country">
                <option value="">Select Plan</option>
                <option>6 months</option>
                <option>1 year</option>
              </select>
              </div>
              <div class="col-md-12"> <textarea type="text" rows="4" cols="50" placeholder="your Query Goes Here..."  className="form-control  my-1"/></div>
              <div class="col-md-12"> <button class="btn btn-primary" role="button" href="/website">Submit Order</button></div>

              <br/><br/>
             <div className='col-md-12'> 
             <small class="text-muted">
                      We will contact you shortly, 
                      <br/>
                      Note: We'll never share your number with anyone else.
                    </small></div>
            </div>
                
                
              </ Card>

            </div>
            <div class="col-md-5 col-lg-4 order-md-last">

              <ul class="list-group mb-1">
                <li class="list-group-item d-flex justify-content-between lh-sm">
                  <div>
                    <h6 class="my-0">Current Operating</h6>
                    <br/>
                    <span class="text-muted">Secuderabad, Telangana, India</span>
                    <br/>
                    <span class="text-muted">Email : contact@websitea1.com</span>
                    <br/>
                    <span class="text-muted">PH : 9505226507</span>
                  </div>
                </li>             
               </ul>
             


            </div>
          </div>
        </div>

      </div>  
      </>
    )
  }
}

export default Checkout;