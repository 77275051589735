import React, { Component } from 'react';
import IMAGES from '../assets';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronCircleRight, faCartPlus, faChevronDown,faCheck,faEye } from '@fortawesome/free-solid-svg-icons';

import Product3 from "../components/Product3";
import Product2 from "../components/Product2";
import Product from "../components/Product";
import Product4 from "../components/Product4";


class PickDesign extends Component {
    render() {
        return (
            <div>
              <div className='row'>
       {/*   <div class="col-md-12"> <select class="form-select form-control my-1 pull-right w-25" id="country" required="">
                <option value="">Sorting</option>
                <option>Low - High Price</option>
                <option>High - Low Price</option>
                <option>Recently Added</option>
                <option>Best Sellers</option>
              </select></div> */}

              <div className='col-md-6 mb-1'>
                <Product />
              </div>
              <div className='col-md-6 mb-1'>
              <Product2 />
              </div>
              <div className='col-md-6 mb-1'>
              <Product3 />


              </div>
              <div className='col-md-6 mb-1'>

              <Product4 />

              </div>
            
            
            </div>

            </div>

        )
    }
}

export default PickDesign;