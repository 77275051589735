import React, { Component } from 'react';
//import '../assets/html.css';
import IMAGES from '../assets';
import { Link } from 'react-router-dom';
import { Links } from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket, faBars, faPhone } from '@fortawesome/free-solid-svg-icons';

class Navbar extends Component {
  render() {
    return (
      <div className='menu mh-100' id="invisible">
        <div className='s-nav mb-2'>
          <div className='row'>
            <div className='col-md-3'>
              <div className='logo'>
                <Link to='/'>
                  <img src={IMAGES.logo} alt='Website A1 logo' />
                </Link>
              </div>
              
            </div>
            <div className='col-md-9'>
              <ul className='nav justify-content-end'>

              {Links.map((link) => {
                  const { id, text, url } = link;
                  return <li key={id} className="nav-item">
                    <Link 
                    to={url} 
                    className='nav-link'
                     
                    >{text}</Link>
                  </li>
                 
                })} 
                <li className="nav-item">
<Link to="/contact" class="btn btn-md btn-primary" role="button"><em><FontAwesomeIcon icon={faPhone} /> Contact</em></Link>
</li>
                </ul>
                

            </div>
          </div>
        </div>


        <div className="clear pull-left"></div>
      </div>
    )
  }
}

export default Navbar;