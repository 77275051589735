import React, { Component } from 'react';
import PageHero from './PageHero';
import IMAGES from '../assets';
import Heading from "../components/Heading";
import { Button, Form, Card } from 'react-bootstrap';
import { Navbar, Sidebar, Footer } from '../components';
import { faChevronCircleRight, faMinus, faChevronDown, faCheck, faEye, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';




class Checkout extends Component {
  render() {
    return (
      <>
        <Navbar />
        <Sidebar />
        <div className="body-bgcolor" align="left">
          <div className='container'>
            <div className='col-12'>
              <hr class="featurette-divider my-1" />
              <Heading heading="Best" catchy=" Package" info="in the market." />
            </div>
            <div className='row'>
              <div className='col-md-4 mb-1'>

                <div class="card rounded-3 shadow-sm box-shadow">
                  <div class="card-header bg-info">
                    <h4 class="my-0 fw-normal white">Individual</h4>
                  </div>
                  <div class="card-body">
                    <div><h1 class="card-title pricing-card-title pull-left">₹333</h1><small class="text-muted fw-light">/ Month</small></div>
                    <div className='clear'></div>
                    <small class="text-muted fw-light">Per year 3,999 INR</small>
                    <button type="submit" class="btn-lg btn-block btn-outline-primary mt-1 text-white" style={{background:"#298fca"}}>Select</button>
                    <div className='clear'></div>
                    <ul class="list-unstyled">
                      <li>Free domain for 1 year.</li>
                      <li>Hosting for 1 year.</li>
                      <li>Unlimited SSD disk*</li>
                      <li>1 free email</li>
                      <li>customizable web forms</li>
                      <li>Call Support</li>
                      <li>SEO Friendly Design</li>
                      <li> Mobile Responsive Design</li>
                      <li> Integrated Google Maps</li>
                      <li>Integrated Google Analytics</li>
                      <li>Design Change every year</li>
                      <li>100 SMS Credits</li>
                      <li>100 HTML In-App Mails</li>
                      <li>Image Gallery Upto 10 Images</li>
                      <li>Upto 5 Pages</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-md-4 mb-1'>

                <div class="card rounded-3 shadow-sm box-shadow">
                  <div class="card-header bg-success">
                    <h4 class="my-0 fw-normal white" >Professional</h4>
                  </div>
                  <div class="card-body">
                    <div><h1 class="card-title pricing-card-title pull-left">₹383</h1><small class="text-muted fw-light">/ Month</small></div>
                    <div className='clear'></div>
                    <small class="text-muted fw-light">Per year 4,599 INR</small>
                    <button type="submit" class="btn-lg btn-block btn-outline-primary mt-1">Select</button>
                    <div className='clear'></div>
                    <ul class="list-unstyled">
                      <li>Free domain for 1 year.</li>
                      <li>Hosting for 1 year.</li>
                      <li>Unlimited SSD disk*</li>
                      <li>3 Free email</li>
                      <li>customizable web forms</li>
                      <li>Call Support</li>
                      <li>SEO Friendly Design</li>
                      <li>Mobile Responsive Design</li>
                      <li>Integrated Google Maps</li>
                      <li>Integrated Google Analytics</li>
                      <li>Design Change every year</li>
                      <li>300 SMS Credits</li>
                      <li>300 HTML In-App Mails</li>
                      <li>Image Gallery Upto 15 Images</li>
                      <li>Upto 10 Pages</li>
                    </ul>

                  </div>
                </div>
              </div>
              <div className='col-md-4 mb-1'>
                <div class="card rounded-3 shadow-sm box-shadow">
                  <div class="card-header bg-primary">
                    <h4 class="my-0 fw-normal white">Business</h4>
                  </div>
                  <div class="card-body" >
                    <div><h1 class="card-title pricing-card-title pull-left">₹499</h1><small class="text-muted fw-light">/ Month</small></div>
                    <div className='clear'></div>
                    <small class="text-muted fw-light">Per year 5,999 INR</small>
                    <button type="submit" class="btn-lg btn-block btn-outline-primary mt-1">Select</button>
                    <div className='clear'></div>

                    <ul class="list-unstyled">
                      <li>Free domain for 1 year.</li>
                      <li>Hosting for 1 year.</li>
                      <li>Unlimited SSD disk*</li>
                      <li>5 Free email</li>
                      <li>customizable web forms</li>
                      <li>Call Support</li>
                      <li>SEO Friendly Design</li>
                      <li> Mobile Responsive Design</li>
                      <li> Integrated Google Maps</li>
                      <li>Integrated Google Analytics</li>
                      <li>Design Change every year</li>
                      <li>600 SMS Credits</li>
                      <li>600 HTML In-App Mails</li>
                      <li>Image Gallery Upto 25 Images</li>
                      <li>Upto 15 Pages</li>


                    </ul>


                  </div>
                </div>
              </div>
            </div>
            <div className='my-2'><Heading heading="Attractive" catchy=" Features" info="Everything you need is here." /></div>
            <div class="row features">
              <div className='col-md-4 mb-1'>
                <span><img src={IMAGES.formhandling} className="img-fluid animated bounceIn" /></span>
                <h2 class="display-4 mt-1 wow  animated fadeInUp">Form Handling</h2>
                <p class="mt-1 wow animated  fadeInUp">Form submissions are easily captured by Website A1 which will email
                  the data to a specified address.</p>
              </div>
              <div className='col-md-4 mb-1'>
                <span><img src={IMAGES.responsive} className="img-fluid animated bounceIn" /></span>
                <h2 class="display-4 mt-1 wow animated fadeInUp ">Responsive design</h2>
                <p class="mt-1 wow animated fadeInUp ">Your website adapts to different screen sizes so it looks great on
                  any device.</p>

              </div>
              <div className='col-md-4 mb-1'>
                <span><img src={IMAGES.hosting} className="img-fluid animated bounceIn" /></span>
                <h2 class="display-4 mt-1 wow animated fadeInUp ">Fast and reliable hosting</h2>
                <p class="mt-1 wow animated fadeInUp ">We use lightning fast cloud hosting to ensure speed and
                  availability
                  you don't even have to think about it!.</p>

              </div>

              <div className='col-md-4 mb-1'>
                <span><img src={IMAGES.gallery} className="img-fluid animated bounceIn" /></span>
                <h2 class="display-4 mt-1 wow animated fadeInUp ">Image Gallery</h2>
                <p class="mt-1 wow animated fadeInUp ">You can send images online, we'll resize and crop images in your image library. In
                  addition, you can also use the general image library.</p>

              </div>
              <div className='col-md-4 mb-1'>
                <span><img src={IMAGES.custom} className="img-fluid animated bounceIn" /></span>
                <h2 class="display-4 mt-1 wow animated fadeInUp ">Custom Pages</h2>
                <p class="mt-1 wow animated fadeInUp ">Create and save full page templates which you can access and use to
                  get your own pages ready faster.</p>

              </div>
              <div className='col-md-4 mb-1'>
                <span><img src={IMAGES.support} className="img-fluid animated bounceIn" /></span>
                <h2 class="display-4 mt-1 wow animated fadeInUp ">Personalised support</h2>
                <p class="mt-1 wow animated fadeInUp ">Got questions? We offer personalised support and guidance to help
                  you
                  build your best website.</p>
              </div>
            </div>


          </div>
        </div>
      </>
    )
  }
}

export default Checkout;