import React, { Component } from 'react';
import IMAGES from "../assets/index";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCartPlus, faICursor, faMousePointer } from '@fortawesome/free-solid-svg-icons'



class LivePreview extends Component {
  render() {
    return (
      <div className='row livepreview'>
        <div className='col-md-4  py-1'>
          <Link to="/website" className='btn btn-primary'> <FontAwesomeIcon icon={faArrowLeft} size="1x" /> Back</Link>
        </div>
        <div className='col-md-4'>
        <h1 className='display-3 white mt-1 pull-left'>Summer Template</h1>         
        </div>
        <div className='col-md-4 py-1'>
          <div className='clear'></div>
          <Link to="/pricing" class="btn btn-success pull-right">
          <FontAwesomeIcon icon={faMousePointer} size="1x" /> Select </Link>
        </div>
      </div>
    )
  }
}

export default LivePreview;