import React, { Component } from 'react';
//import '../assets/html.css';
import IMAGES from '../assets';
import { Link } from 'react-router-dom';
import { Links } from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket, faBars, faSignOut, faPhone } from '@fortawesome/free-solid-svg-icons';


class NavbarLogin extends Component {
  render() {
    return (
      <div className='menu mh-100' id="invisible">
        <div className='s-nav mb-2'>
          <div className='row'>
            <div className='col-md-3'>
              <div className='logo'>
                <Link to='/'>
                  <img src={IMAGES.logo} alt='Website A1 logo' />
                </Link>
              </div>

            </div>
            <div className='col-md-9'>
              <ul className='nav justify-content-end'>
                            <li>
                  <Link to="/PreSupport" class="btn btn-success pull-left mt-1"><FontAwesomeIcon icon={faPhone} /> &nbsp; Support</Link>
                </li>
                <li>
                  <Link to="/Signin" class="btn btn-danger pull-left mt-1"><FontAwesomeIcon icon={faSignOut} /> Logout</Link>
                </li>
             </ul>


            </div>
          </div>
        </div>


        <div className="clear pull-left"></div>
      </div>
    )
  }
}

export default NavbarLogin;