import React, { useState } from 'react';
import axios from "axios";
import { Button, Form, Card } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';


function CreateClient() {

    const [input, setInput] = useState({
        name2: "",
        email2: "",
        mobile2: "",
        password2: "",
        content2: ""
    })

    const [errinput, setErrInput] = useState({
        name2Err: "",
        email2Err: "",
        phone2Err: "",
        password2Err: "",
        content2Err: ""
    })

    function hangleChange(event) {
        const { name, value } = event.target;
        setInput(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        })
    }

    // function handleClick(event) {
    //     event.preventDefault();
    //     console.log(input);
    //     const newClient = {
    //         name2: input.name2,
    //         email2: input.email2,
    //         mobile2: input.mobile2,
    //         password2: input.password2,
    //         content2: input.content2
    //     }

    //     axios.post("http://localhost:8090/create", newClient)

    // }

    const handleClick = async () => {
        if (validForm()) {
            // console.log("valid")
            // event.preventDefault();
            console.log(input);
            const newClient = {
                name2: input.name2,
                email2: input.email2,
                mobile2: input.mobile2,
                password2: input.password2,
                content2: input.content2
            }

            axios.post("http://localhost:8090/create", newClient)


            try {

                console.log(newClient)
            } catch (e) {
                alert("Successfully Registered")
                toast.Success("Please Enter Valid Input!", {
                    position: toast.POSITION.TOP_RIGHT,
                    type: toast.TYPE.ERROR,
                    theme: "colored"
                    
                });
            }
        }
        else {
            // console.log("Invalid")
            
            toast.error("Please Enter Valid Input!", {
                position: toast.POSITION.TOP_RIGHT,
                type: toast.TYPE.ERROR,
                theme: "colored"
                
            });

        }
    }


    const validForm = () => {
        let formIsValid = true;
        const validEmail = RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        setErrInput({
            name2Err: "",
            email2Err: "",
            mobile2Err: "",
            password2Err: "",
            content2Err: "",
        })
        if (input.name2 === '') {
            formIsValid = false
            setErrInput(prevState => ({
                ...prevState, name2Err: 'Please Enter Name'
            }))
        }
        if (input.email2 === '') {
            formIsValid = false
            setErrInput(prevState => ({
                ...prevState, email2Err: 'Please Enter email'
            }))
        }
        if (input.mobile2 === '') {
            formIsValid = false
            setErrInput(prevState => ({
                ...prevState, mobile2Err: 'Please Enter Mobile number'
            }))
        }
        if (input.password2 === '') {
            formIsValid = false
            setErrInput(prevState => ({
                ...prevState, password2Err: 'Please Enter Password'
            }))
        }
        if (input.content2 !== '' && input.password2 !== input.content2) {
            formIsValid = false
            setErrInput(prevState => ({
                ...prevState, content2Err: 'Password doesnt Matched'
            }))
        }
        return formIsValid
    }

    return (
        <div className='container'>
            <div className="row">
                <div className="col w-50">
                    <Card className="my-1 p-2 login">
                    <ToastContainer />


                            <input onChange={hangleChange} name="name2" autoComplete='off' placeholder='Your org name' className="form-control p-1 mt-1 w-100" />
                            {
                                errinput.name2Err.length > 0 && <span style={{ color: "red" }}>{errinput.name2Err}</span>
                            }

                            <input onChange={hangleChange} name="mobile" autoComplete='off' placeholder='Your mobile' className="form-control p-1 mt-1 w-100"></input>
                            {
                                errinput.email2Err.length > 0 && <span style={{ color: "red" }}>{errinput.email2Err}</span>
                            }
                             <input onChange={hangleChange} name="email" autoComplete='off' placeholder='Your Email' className="form-control p-1 mt-1 w-100"></input>
                            {
                                errinput.email2Err.length > 0 && <span style={{ color: "red" }}>{errinput.email2Err}</span>
                            }
                           {/*<input onChange={hangleChange} name="mobile2" autoComplete='off' placeholder='Your mobile number' className="form-control p-1 mt-1 w-100"></input>
                            {
                                errinput.mobile2Err.length > 0 && <span style={{ color: "red" }}>{errinput.mobile2Err}</span>
                            }*/}
                            <input onChange={hangleChange} name="password2" type="password" autoComplete='off' placeholder='Password' className="form-control p-1 mt-1 w-100"></input>
                            {
                                errinput.password2Err.length > 0 && <span style={{ color: "red" }}>{errinput.password2Err}</span>
                            }
                            <input onChange={hangleChange} name="content2" required type="password" autoComplete='off' placeholder='Confirm Password' className="form-control p-1 mt-1 w-100"></input>
                            {
                                errinput.content2Err.length > 0 && <span style={{ color: "red" }}>{errinput.content2Err}</span>
                            }

                            {/*<button onClick={handleClick} href="/signIn" className='btn btn-lg btn-primary w-100 mt-1 py-1'>Submit</button>*/}
                            <br/>
                            <Link to="/PickDesign" class="btn btn-warning pull-left"> Signup</Link>

                        
                    </ Card>
                </div>
            </div>
            <div className='col-md-6'></div>
        </div>

    )
}

export default CreateClient;